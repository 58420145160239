export const GET_USERS = 'GET_USERS';
export const DELETE_USERS = 'DELETE_USERS';

//Product
export const GET_PRODUCT_DETAIL_REQUEST = 'GET_PRODUCT_DETAIL_REQUEST';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILED = 'GET_PRODUCT_DETAIL_FAILED';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const GET_PRODUCTS_BY_STORE_ID_REQUEST = 'GET_PRODUCTS_BY_STORE_ID_REQUEST';
export const GET_PRODUCTS_BY_STORE_ID_SUCCESS = 'GET_PRODUCTS_BY_STORE_ID_SUCCESS';
export const GET_PRODUCTS_BY_STORE_ID_FAILED = 'GET_PRODUCTS_BY_STORE_ID_FAILED';

export const GET_PRODUCTS_BY_PARAMS_REQUEST = 'GET_PRODUCTS_BY_PARAMS_REQUEST';
export const GET_PRODUCTS_BY_PARAMS_SUCCESS = 'GET_PRODUCTS_BY_PARAMS_SUCCESS';
export const GET_PRODUCTS_BY_PARAMS_FAILED = 'GET_PRODUCTS_BY_PARAMS_FAILED';

//Cart
export const GET_PRODUCTS_CART_BY_USER_REQUEST = 'GET_PRODUCTS_CART_BY_USER_REQUEST';
export const GET_PRODUCTS_CART_BY_USER_SUCCESS = 'GET_PRODUCTS_CART_BY_USER_SUCCESS';
export const GET_PRODUCTS_CART_BY_USER_FAILED = 'GET_PRODUCTS_CART_BY_USER_FAILED';

export const GET_PRODUCTS_CART_SELECTED_SEND = 'GET_PRODUCTS_CART_SELECTED_SEND';
export const GET_CART_PAYMENT = 'GET_CART_PAYMENT';

export const ADD_PRODUCTS_TO_CART_REQUEST = 'ADD_PRODUCTS_TO_CART_REQUEST';
export const ADD_PRODUCTS_TO_CART_SUCCESS = 'ADD_PRODUCTS_TO_CART_SUCCESS';
export const ADD_PRODUCTS_TO_CART_FAILED = 'ADD_PRODUCTS_TO_CART_FAILED';

//Store
export const GET_STORES_REQUEST = 'GET_STORES_REQUEST';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILED = 'GET_STORES_FAILED';

export const GET_STOREINFO_BY_ID_REQUEST = 'GET_STOREINFO_BY_ID_REQUEST';
export const GET_STOREINFO_BY_ID_SUCCESS = 'GET_STOREINFO_BY_ID_SUCCESS';
export const GET_STOREINFO_BY_ID_FAILED = 'GET_STOREINFO_BY_ID_FAILED';

//Vourcher
export const GET_VOUCHER_BY_STORE_ID_REQUEST = 'GET_VOUCHER_BY_STORE_ID_REQUEST';
export const GET_VOUCHER_BY_STORE_ID_SUCCESS = 'GET_VOUCHER_BY_STORE_ID_SUCCESS';
export const GET_VOUCHER_BY_STORE_ID_FAILED = 'GET_VOUCHER_BY_STORE_ID_FAILED';

export const SAVE_VOUCHER_BY_USER_REQUEST = 'SAVE_VOUCHER_BY_USER_REQUEST';
export const SAVE_VOUCHER_BY_USER_SUCCESS = 'SAVE_VOUCHER_BY_USER_SUCCESS';
export const SAVE_VOUCHER_BY_USER_FAILED = 'SAVE_VOUCHER_BY_USER_FAILED';

//Feedback
export const GET_FEEDBACK_BY_PRODUCT_ID_REQUEST = 'GET_FEEDBACK_BY_PRODUCT_ID_REQUEST';
export const GET_FEEDBACK_BY_PRODUCT_ID_SUCCESS = 'GET_FEEDBACK_BY_PRODUCT_ID_SUCCESS';
export const GET_FEEDBACK_BY_PRODUCT_ID_FAILED = 'GET_FEEDBACK_BY_PRODUCT_ID_FAILED';

export const RESET_FLAG_REQUEST = 'RESET_FLAG_REQUEST';

//Payment
export const POST_ORDER_REQUEST = 'POST_ORDER_REQUEST';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAILED = 'POST_ORDER_FAILED';